import {
  curry,
} from 'crocks';

export const fetchA2 = curry((url, options) => fetch(url, options));

export const fetchJsonA2 = curry((url, {method = 'POST', headers, body, ...options}) => fetchA2(url, {
  method,
  ...options,
  headers: {
    'Content-Type': 'application/json',
    ...headers
  },
  body: JSON.stringify(body)
}));

export const resolveResponse = (method = 'json') => response => {
  return !(response instanceof Response)
    ? response
    : response.clone()[method]().catch(
      () => response.clone().text().catch(
        () => response.clone().formData().catch(
          ()  => response.clone().blob().catch(
            () => response.clone().arrayBuffer()
          )
        )
      )
    )
    .catch(() => response)
};
