import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const LOGIN_PAGE = '/auth/login';
export const REGISTER_PAGE = '/auth/register';
export const VERIFICATION_PAGE = '/auth/verification';
export const SET_PHONE_PAGE = '/auth/set-phone';
export const LOST_PASSWORD_PAGE = '/auth/lost-password';
export const RESET_PASSWORD_PAGE = '/auth/reset-password';
export const CONFIRMATION_PAGE = '/auth/confirmation/:id';
export const MAGIC_LINK = '/auth/magic-link/:code';
export const LOGOUT_PAGE = '/auth/logout';
export const NOT_ALLOWED = '/auth/not-allowed';

export const NotAllowedRoute = getExactHiddenRoute(
    NOT_ALLOWED,
    lazy(() => import('./permissionDenied')),
    null
);

export const LogoutRoute = getExactHiddenRoute(
    LOGOUT_PAGE,
    lazy(() => import('./logout')),
    null
);

export const ConfirmationRoute = getExactHiddenRoute(
  CONFIRMATION_PAGE,
  lazy(() => import('./confirmation')),
  null
);

export const LoginRoute = getExactHiddenRoute(
  LOGIN_PAGE,
  lazy(() => import('./index')),
  null
);

export const RegisterRoute = getExactHiddenRoute(
  REGISTER_PAGE,
  lazy(() => import('./register')),
  null
);

export const VerificationRoute = getExactHiddenRoute(
  VERIFICATION_PAGE,
  lazy(() => import('./verification')),
  null
);

export const SetPhoneRoute = getExactHiddenRoute(
  SET_PHONE_PAGE,
  lazy(() => import('./setPhone')),
  null
);

export const LostPasswordRoute = getExactHiddenRoute(
  LOST_PASSWORD_PAGE,
  lazy(() => import('./lostPassword')),
  null
);

export const ResetPasswordRoute = getExactHiddenRoute(
  RESET_PASSWORD_PAGE,
  lazy(() => import('./resetPassword')),
  null
);

export const MagicLinkRoute = getExactHiddenRoute(
  MAGIC_LINK,
  lazy(() => import('./magicLink')),
  null
);


const AuthRoute = (
  <>
    {RegisterRoute}
    {LoginRoute}
    {VerificationRoute}
    {SetPhoneRoute}
    {LostPasswordRoute}
    {ConfirmationRoute}
    {ResetPasswordRoute}
    {MagicLinkRoute}
    {LogoutRoute}
    {NotAllowedRoute}
  </>
)

export default AuthRoute;
