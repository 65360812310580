import {createContextHook} from '../util/react';
import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, isSupported} from 'firebase/messaging';
import {useEffect, useState} from 'react';
import axios from 'axios';
import env from '../env';
import {getCookie} from '../util/cookieUtils';

const isNotificationDecided = (which = /granted|denied/i) => typeof Notification !== 'undefined' && which.test(Notification?.permission);

const {FirebaseContext, FirebaseContextProvider, useFirebase} = createContextHook('firebase', () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentToken, setCurrentToken] = useState(null);
  const [supportsFCM, setSupportsFCM] = useState(null);
  const [messaging, setMessaging] = useState(null);

  const firebaseConfig = {
    apiKey: 'AIzaSyAI43V44UrCEIOjttkhbJDB-4Q7K6v_mr8',
    authDomain: 'milego-fc3d6.firebaseapp.com',
    projectId: 'milego-fc3d6',
    storageBucket: 'milego-fc3d6.appspot.com',
    messagingSenderId: '895020268515',
    appId: '1:895020268515:web:22013699e437052ce47ed6',
    measurementId: 'G-WZPB0LCC4Z'
  };

  const app = initializeApp(firebaseConfig);

  useEffect(() => {
    isSupported().then(supports => {
      setSupportsFCM(supports);
      if (supports) {
        setMessaging(getMessaging(app));
        setIsOpen(!isNotificationDecided());
      } else {
        setIsOpen(false);
        console.warn('This browser does not support Firebase Cloud Messaging.');
      }
    });
  }, [isOpen]);

  useEffect(() => {
    isSupported().then(isSupported => {
      if (!isSupported) return;
      if (!isNotificationDecided(/granted/i)) return;
      if (currentToken) return;
      subscribeUser();
    });
  }, [messaging, currentToken, typeof Notification !== 'undefined' ? Notification?.permission : null]);

  const subscribeUser = async () => {
    const userId = getCookie('userId');
    if (!supportsFCM || !userId) {
      setIsOpen(false);
      return;
    }

    if (messaging) {
      getToken(messaging, {vapidKey: env.FIREBASE_VAPID_KEY}).then((currentToken) => {
        if (currentToken) {
          setIsOpen(false);
          setCurrentToken(currentToken);
          axios.post(`${env.NOTIFICATION_URL}/attach-user-to-token`, {
            token: currentToken,
            deviceType: getBrowser(),
            userId
          }).then((res) => {
            // handle success
          }).catch((e) => console.log('error', e));
        } else {
          setIsOpen(false);
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        setIsOpen(false);
        console.log('An error occurred while retrieving token.', err);
      });
    }
  };

  const requestPermission = () => {
    if (!supportsFCM) return;

    if (typeof Notification === 'undefined') {
      console.log('This browser does not support system notifications.');
      return;
    }

    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        subscribeUser();
      } else {
        setIsOpen(false);
      }
    }).catch((e) => {
      console.log(e);
    });
  };

  const getBrowser = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    let browser;

    if (userAgent.includes('chrome')) {
      browser = 'chrome';
    } else if (userAgent.includes('safari')) {
      browser = 'safari';
    } else if (userAgent.includes('firefox')) {
      browser = 'firefox';
    } else {
      browser = userAgent;
    }

    return browser;
  };

  return {requestPermission, isOpen, setIsOpen, currentToken, subscribeUser};
});

export {FirebaseContext, FirebaseContextProvider, useFirebase};
