import {GET_SETTINGS} from 'api/graphql/Settings';
import {createContextHook} from 'util/react';
import {safe, getProp, and, isNumber, not, propEq, Maybe, find, isArray} from 'crocks';
import {useQuery} from '@apollo/client';

export const {
  SettingsContext,
  SettingsContextProvider,
  useSettings
} = createContextHook('Settings', () => {
  const response = useQuery(GET_SETTINGS);

  /**
   * @type {import('gql/graphql').GetSettingsQuery}
   */
  const data  = response?.data;

  return {
    insuranceRate: getProp('settings', data)
    .chain(find(propEq('name', 'insurance_rate')))
    .map(parseFloat)
    .chain(safe(and(isNumber, not(isNaN))))
    .option(10),

    vatPercentage: getProp('settings', data)
    .chain(find(propEq('name', 'vatPercentage')))
    .chain(getProp('value'))
    .alt(Maybe.of(21))
    .map(parseFloat)
    .chain(safe(and(isNumber, not(isNaN))))
    .option(0),

    paymentProviders: getProp('settings', data)
    .chain(find(propEq('name', 'payment_providers')))
    .chain(getProp('value'))
    .chain(safe(isArray))
    .option([])
  };
})
