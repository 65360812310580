import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const ORDER_DETAILS = '/order/details/:id';
export const CANCEL_ORDER_ROUTE = '/order/cancel/:id';
export const ORDER_PAYMENT_RESPONSE_ROUTE = '/order/payment-response';

export const OrderDetailsRoute = getExactHiddenRoute(
  ORDER_DETAILS,
  lazy(() => import('./orderDetails')),
  null
);

export const CancelOrderRoute = getExactHiddenRoute(
  CANCEL_ORDER_ROUTE,
  lazy(() => import('./cancelOrder')),
  null
);

export const OrderPaymentResponseRoute = getExactHiddenRoute(
  ORDER_PAYMENT_RESPONSE_ROUTE,
  lazy(() => import('./paymentResponse')),
  null
);

const OrderRoute = (
  <>
    {CancelOrderRoute}
    {OrderDetailsRoute}
    {OrderPaymentResponseRoute}
  </>
)

export default OrderRoute;
