import React from 'react';
import {Route} from 'react-router-dom';

import NotFoundLayout from 'layout/NotFoundLayout';
import AuthRoute from 'pages/auth/routes';
import CreateOrderRoute from 'pages/create-order/routes';
import DriverRoute from 'pages/driver/routes';
import NotificationListRoute from './pages/notifications/routes';
import MessageListRoute from './pages/messages/routes';
import ClientRoute from './pages/client/routes';
import OrderStatusesRoute from './pages/order-status/routes';
import OrderRoute from './pages/order/routes';
import StaticPageRoute from './pages/page/routes';
import AllReviewsRoute from './pages/reviews/routes';
import HomeRoute from './pages/home/routes';

const Routes = (
  <>
    {HomeRoute}
    {AuthRoute}
    {CreateOrderRoute}
    {DriverRoute}
    {NotificationListRoute}
    {MessageListRoute}
    {ClientRoute}
    {OrderRoute}
    {OrderStatusesRoute}
    {StaticPageRoute}
    {AllReviewsRoute}
    <Route isHidden path='*' element={<NotFoundLayout/>}/>
  </>
);

export default Routes;
