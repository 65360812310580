import env from 'env';
import {
  Async,
  getPropOr,
  hasProp,
  identity,
  ifElse,
  map,
  mapProps,
  pick,
  pipe,
  propEq,
  tap,
} from 'crocks';
import {createContextHook} from 'util/react';
import {fetchJsonA2, resolveResponse} from 'util/fetch';
import {useAsyncEffect} from 'hook/useAsync';
import {useClientOrder} from 'feature/order/context/ClientOrderContext';
import {useDebounceAsync} from 'hook/useDebounceAsync';
import {useEffect, useState} from 'react';
import {caseMap} from '@s-e/frontend/flow-control';

export const ERROR = {
  INCOMPLETE_GET_PRICES_PROPS: 'Cannot get courier prices for incomplete parameter values.',
};

export const getMultiParcelPrices = ({origin, destination, cargo}) => (
  fetchJsonA2(`${env.MULTIPARCEL_URL}/multiparcel/get-prices`, {
    body: {from: origin, to: destination, cargos: cargo}
  })
    .then(resolveResponse())
);

export const createMultiparcelShipping = (orderInsertInput, courierShipping) => !courierShipping
  ? Promise.resolve(null)
  : (
    fetchJsonA2(`${env.MULTIPARCEL_URL}/multiparcel/create-shipment`, {body: {order: orderInsertInput, courier: courierShipping}})
    .then(r => r.json())
    .then(caseMap(value => Promise.resolve(value), [
      [propEq('status', 'error'), r => Promise.reject(r?.errors || r)],
    ]))
  );

export const {
  CouriersContext,
  CouriersContextProvider,
  useCouriers
} = createContextHook('Couriers', () => {
  const ctx = useClientOrder();
  const [activeCourier, setActiveCourier] = useState(null);

  const prices = useAsyncEffect(
    useDebounceAsync(Async.fromPromise(getMultiParcelPrices), 1000)
      (pipe(
        pick(['origin', 'destination', 'cargo']),
        mapProps({
          origin: getPropOr(null, 'form'),
          destination: getPropOr(null, 'form'),
          cargo: map(mapProps({
            height: Number,
            length: Number,
            quantity: Number,
            weight: Number,
            width: Number,
          })),
        }),
      )(ctx))

      .chain(ifElse(
        hasProp('errors'),
        result => Async.Rejected(result.errors),
        Async.Resolved,
      )),
    tap(console.error),
    identity,
    [JSON.stringify(ctx?.origin?.form), JSON.stringify(ctx?.destination?.form), JSON.stringify(ctx?.cargo)]
  );

  useEffect(() => {
    if (activeCourier?.total) return;

    setActiveCourier(null);
  }, [activeCourier, setActiveCourier]);

  return {
    getMultiParcelPrices,
    createMultiparcelShipping,
    prices,
    activeCourier,
    setActiveCourier
  };
});
