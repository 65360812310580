import React from 'react';
import Input from './Base/Input';
import Label from './Base/Label';
import ErrorText from './Base/ErrorText';
import Nullable from 'components/atom/Nullable'
import {withComponentFactory} from 'util/react';
import {omit} from 'crocks';
import clsx from 'clsx';

const InputGroup = ({
                      Addon,
                      RightAddon,
                      Input,
                      Label,
                      addonClassName = 'text-gray-400',
                      addonWrapperClassName = 'absolute inset-y-0 flex items-center',
                      className = 'group',
                      inputClassName = 'focus:border-black w-full h-[50px]',
                      inputWrapperWhenLabel = '',
                      inputwrapperClassName = 'relative ',
                      label,
                      twLabel = 'text-base',
                      isRequired,
                      twRequired,
                      below,
                      isInvalid = '',
                      placeholder,
                      ...props
                    }) => (
  <div className={className}>
    <Nullable on={label}>
      <Label className={clsx(twLabel, below && isInvalid && 'text-error', 'font-medium block')}
             htmlFor={props?.id}>
        <>
          {label}
          {isRequired && <span className={`text-red-500 ${twRequired}`}> * </span>}
        </>
      </Label>
    </Nullable>
    <div className={`${label ? inputWrapperWhenLabel : ''} ${inputwrapperClassName}`}>
      <div className={'relative h-fit'}>
        {Addon && (
          <div className={`${addonWrapperClassName} left-0 pl-[18px]`}>
            <Addon className={addonClassName} aria-hidden='true'/>
          </div>)}
        <Input {...{
          ...props,
          placeholder: placeholder,
          className: clsx(inputClassName, Addon && 'pl-[42px]', 'pr-14' && RightAddon, below && isInvalid ? 'border-error border placeholder:text-error' : ' border border-gray-100')
        }} />
        {RightAddon && (
          <div className={`${addonWrapperClassName} right-0 pr-5 text-xl`}>
            {RightAddon}
          </div>)}
      </div>
      <Nullable on={below}>
        <div className={'error-message-block right-0 top-0 md:absolute md:right-4 md:top-4'}>
          {below}
        </div>
      </Nullable>
    </div>

  </div>
);

export default withComponentFactory(InputGroup, {
  mapSetupInComponent: omit(['input', 'ErrorText']),
  Label,
  Input,
  ErrorText,
});
