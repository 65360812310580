import {hasLength} from '@s-e/frontend/pred';
import {not, curry, propSatisfies, isSame, Maybe, flip, isNumber} from 'crocks';
import {isValid, parse} from 'date-fns';


export const gt = curry((a, b) => a < b);
export const lt = curry((a, b) => a > b);
export const gte = curry((a, b) => a <= b);
export const lte = curry((a, b) => a >= b);

export const hasntLength = not(hasLength);
export const lengthOp = curry((op, a, foldable) =>
  propSatisfies('length', op(a), foldable),
);
export const lengthGt = lengthOp(gt);
export const lengthLt = lengthOp(lt);
export const lengthGte = lengthOp(gte);
export const lengthLte = lengthOp(lte);
export const lengthEq = lengthOp(isSame);

export const mPredCmp = curry((pred, m, lens, value) => (
  Maybe.of(pred)
  .ap(lens(value))
  .ap(m)
));

export const mIsSame = mPredCmp(isSame);

export const isPropNum = flip(propSatisfies)(isNumber);

export const isDateFmtValid = curry((fmt, value) => isValid(parse(value, fmt, new Date())));

export const iLike = curry((a, b) => String(a).toLowerCase() === String(b).toLowerCase());
export const iNotLike = curry((a, b) => String(a).toLowerCase() !== String(b).toLowerCase());
