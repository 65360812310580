const Icon = ({...props}) => {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='14' height='14'
         viewBox='0 0 14 14'>
      <defs>
        <linearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#f04e23'/>
          <stop offset='1' stopColor='#f68b1f'/>
        </linearGradient>
      </defs>
      <g id='time' transform='translate(42)'>
        <g id='Group_1623' data-name='Group 1623' transform='translate(-42)'>
          <path id='Path_1727' data-name='Path 1727'
                d='M7,14A7,7,0,1,0,0,7,7.009,7.009,0,0,0,7,14ZM7,.933A6.067,6.067,0,1,1,.933,7,6.072,6.072,0,0,1,7,.933Z'
                fill='url(#linear-gradient)'/>
          <path id='Path_1728' data-name='Path 1728'
                d='M170.175,78.289a.459.459,0,0,0,.292.1.467.467,0,0,0,.292-.831L168.6,75.834V72.325a.467.467,0,1,0-.933,0v3.733a.468.468,0,0,0,.175.364Z'
                transform='translate(-161.134 -69.058)' fill='url(#linear-gradient)'/>
        </g>
      </g>
    </svg>
  )
}

export default Icon

