import MainRoutes from './Routes';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Loader from './components/common/Loader';
import {useLoader} from './context/loader'
import ScrollToTop from './components/atom/ScrollToTop';

function App() {
  const {loading} = useLoader();

  return (
    <>
      {loading && <Loader/>}
      <Routes>
        <Route>
          {MainRoutes}
        </Route>
      </Routes>
    </>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <ScrollToTop/>
      <App/>
    </Router>
  );
}

export default AppWithRouter;
