import {gql} from '@apollo/client';

export const GET_SETTINGS = gql`
    query GetSettings {
        settings {
            name
            value(path: "value")
        }
    }
`

export const GET_PAGE_BY_TITLE = gql`
    query GetPageByTitle($_eq: String = "") {
        page(where: {slug: {_eq: $_eq}}) {
            content
            id
            slug
            title
        }
    }
`

export const FOOTER_PAGES_QUERY = gql`
    query FooterPagesQuery {
        footer_sections {
            footer_pages {
                id
                ord
                title
                url
            }
            id
            title
        }
    }
`;

export const GET_LANGUAGES = gql`
    query GetLanguages {
        language {
            key
            title
        }
    }
`
