const ENV = {
  API_ENDPOINT: process.env.REACT_APP_BACKEND_URL,
  API_SECRET: process.env.REACT_APP_BACKEND_SECRET,

  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  IS_DEV: ['true', 1, '1', 't', 'yes', 'y'].includes(process.env.REACT_APP_IS_DEV.toLowerCase()),

  QUERY_PROTOCOL: process.env.REACT_APP_QUERY_PROTOCOL,
  SUBSCRIPTION_PROTOCOL: process.env.REACT_APP_SUBSCRIPTION_PROTOCOL,
  SUBSCRIPTION_BASE_URL: process.env.REACT_APP_SUBSCRIPTION_BASE_URL,
  MULTIPARCEL_URL: process.env.REACT_APP_MULTIPARCEL_URL,
  TRANSLATION_URL: process.env.REACT_APP_TRANSLATION_URL,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
  GOOGLE_RECAPTCHA_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY,
  NOTIFICATION_URL: process.env.REACT_APP_NOTIFICATION_URL,
  FILE_SERVICE_URL: process.env.REACT_APP_FILE_SERVICE_URL,
  FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
};

export default ENV;
