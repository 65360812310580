import {FORM_FIELD} from 'hook/useResultForm';
import {always} from 'util/func';
import {hasLength, isEmail} from '@s-e/frontend/pred';
import {validPhone} from 'util/helpers';
import {isEmpty, or, pick, pipe, setProp,} from 'crocks';

export const getDetailsFormFields = ({t, type, wasSubmitted}) => ({
  hasLift: FORM_FIELD.BOOL({
    validator: always(true),
    props: {
      value: ({value}) => value,
      options: always([{text: t('yes'), value: true}, {text: t('no'), value: false}]),
      onChange: ({set}) => set,
      'data-testid': always(`${type}-has-lift`)
    }
  }),
  needTakeout: FORM_FIELD.BOOL({
    validator: always(true),
    props: {
      value: ({value}) => value,
      options: always([{text: t('yes'), value: true}, {text: t('no'), value: false}]),
      onChange: ({set}) => set,
      'data-testid': always(`${type}-has-need-takeout`),
    }
  }),
  carryYourself: FORM_FIELD.BOOL({
    validator: always(true),
    props: {
      value: ({value}) => value,
      options: always([{text: t('yes'), value: true}, {text: t('no'), value: false}]),
      onChange: ({set}) => set,
      'data-testid': always(`${type}-carry-yourself`),
    }
  }),
  name: FORM_FIELD.TEXT({
    label: `${t('name')} *`,
    validator: hasLength,
    showValidationBelow: wasSubmitted,
    props: {
      placeholder: always(`${t('name')} *`),
      twLabel: always('text-xxs mb-1'),
      isInvalid: ({isValid}) => !isValid,
      'data-testid': always(`${type}-name`),
    }
  }),
  phone: FORM_FIELD.TEXT({
    validator: validPhone,
    message: t('incorrect_phone_number'),
    label: `${t('phone')} *`,
    showValidationBelow: wasSubmitted,
    props: {
      type: always('tel'),
      placeholder: always('601 23456'),
      labelClass: always('text-xxs mb-1'),
      isInvalid: ({isValid}) => !isValid,
      onChange: ({set}) => (event, code) => {
        set(code + event.target.value)
      },
      'data-testid': always(`${type}-phone`),
    }
  }),
  companyName: FORM_FIELD.TEXT({
    label: t('company_name'),
    validator: () => true,
    props: {
      placeholder: always(t('company_name')),
      twLabel: always('text-xxs mb-1'),
      'data-testid': always(`${type}-company-name`),
    }
  }),
  companyPhone: FORM_FIELD.TEXT({
    validator: or(isEmpty, validPhone),
    label: t('phone'),
    showValidationBelow: wasSubmitted,
    props: {
      type: always('tel'),
      placeholder: always('601 23456'),
      labelClass: always('text-xxs mb-1'),
      onChange: ({set}) => (event, code) => {
        set(event.target.value ? (code + event.target.value) : '')
      },
      'data-testid': always(`${type}-company-phone`),
      isInvalid: ({isValid}) => !isValid,
    }
  }),
  comment: FORM_FIELD.TEXT({
    label: t('comment'),
    validator: always(true),
    props: {
      placeholder: always(t('comment')),
      twLabel: always('text-xxs mb-1'),
      'data-testid': always(`${type}-comment`),
    }
  }),
  floor: FORM_FIELD.TEXT({
    initial: 1,
    validator: always(true),
    props: {
      onChange: ({set}) => set,
      'data-testid': always(`${type}-floor`),
    }
  }),
  email: FORM_FIELD.TEXT({
    validator: isEmail,
    message: t('must_email'),
    label: `${t('email')} *`,
    showValidationBelow: wasSubmitted,
    props: {
      placeholder: always(`${t('email')} *`),
      type: always('email'),
      isInvalid: ({isValid}) => !isValid,
      twLabel: always('text-xxs mb-1'),
      'data-testid': always(`${type}-email`)
    }
  }),
});

export const contactFormToGql = form => pipe(
  pick([
    'comment',
    'email',
    'name',
    'phone',
  ]),
  setProp('company_name', form?.companyName),
  setProp('company_phone', form?.companyPhone),
)(form)
