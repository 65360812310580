const Icon = ({...props}) => {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='14' height='14'
         viewBox='0 0 14 14'>
      <defs>
        <linearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#f04e23'/>
          <stop offset='1' stopColor='#f68b1f'/>
        </linearGradient>
      </defs>
      <g id='calendar' transform='translate(-1 -1)'>
        <path id='Path_1720' data-name='Path 1720'
              d='M4.063,1a.438.438,0,0,0-.437.438v.438H2.313A1.316,1.316,0,0,0,1,3.188v10.5A1.289,1.289,0,0,0,2.313,15H13.688A1.289,1.289,0,0,0,15,13.688V3.188a1.289,1.289,0,0,0-1.312-1.312H12.375V1.438a.438.438,0,0,0-.875,0v.438h-7V1.438A.413.413,0,0,0,4.063,1ZM14.125,13.688a.413.413,0,0,1-.437.438H2.313a.413.413,0,0,1-.437-.437V5.375h12.25ZM11.5,2.75v.438a.438.438,0,0,0,.875,0V2.75h1.313a.413.413,0,0,1,.438.438V4.5H1.875V3.188a.413.413,0,0,1,.438-.437H3.625v.438a.413.413,0,0,0,.438.438A.413.413,0,0,0,4.5,3.188V2.75Z'
              fill='url(#linear-gradient)'/>
        <path id='Path_1721' data-name='Path 1721'
              d='M6.538,16.125h1.75a.413.413,0,0,0,.438-.437v-1.75a.413.413,0,0,0-.437-.437H6.538a.413.413,0,0,0-.437.438v1.75A.413.413,0,0,0,6.538,16.125Zm.438-1.75H7.85v.875H6.975Z'
              transform='translate(-2.869 -7.031)' fill='url(#linear-gradient)'/>
        <path id='Path_1722' data-name='Path 1722'
              d='M14.538,16.125h1.75a.413.413,0,0,0,.438-.437v-1.75a.413.413,0,0,0-.437-.437h-1.75a.413.413,0,0,0-.437.438v1.75A.413.413,0,0,0,14.538,16.125Zm.438-1.75h.875v.875h-.875Z'
              transform='translate(-7.369 -7.031)' fill='url(#linear-gradient)'/>
        <path id='Path_1723' data-name='Path 1723'
              d='M22.538,16.125h1.75a.413.413,0,0,0,.438-.437v-1.75a.413.413,0,0,0-.437-.437h-1.75a.413.413,0,0,0-.437.438v1.75A.413.413,0,0,0,22.538,16.125Zm.438-1.75h.875v.875h-.875Z'
              transform='translate(-11.869 -7.031)' fill='url(#linear-gradient)'/>
        <path id='Path_1724' data-name='Path 1724'
              d='M6.538,25.125h1.75a.413.413,0,0,0,.438-.437v-1.75a.413.413,0,0,0-.437-.437H6.538a.413.413,0,0,0-.437.438v1.75A.413.413,0,0,0,6.538,25.125Zm.438-1.75H7.85v.875H6.975Z'
              transform='translate(-2.869 -12.094)' fill='url(#linear-gradient)'/>
        <path id='Path_1725' data-name='Path 1725'
              d='M14.538,25.125h1.75a.413.413,0,0,0,.438-.437v-1.75a.413.413,0,0,0-.437-.437h-1.75a.413.413,0,0,0-.437.438v1.75A.413.413,0,0,0,14.538,25.125Zm.438-1.75h.875v.875h-.875Z'
              transform='translate(-7.369 -12.094)' fill='url(#linear-gradient)'/>
        <path id='Path_1726' data-name='Path 1726'
              d='M22.538,25.125h1.75a.413.413,0,0,0,.438-.437v-1.75a.413.413,0,0,0-.437-.437h-1.75a.413.413,0,0,0-.437.438v1.75A.413.413,0,0,0,22.538,25.125Zm.438-1.75h.875v.875h-.875Z'
              transform='translate(-11.869 -12.094)' fill='url(#linear-gradient)'/>
      </g>
    </svg>
  )
}

export default Icon

