import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const NOTIFICATIONS = '/notifications';
export const NOTIFICATION = '/notifications/:id';

export const NotificationsRoute = getExactHiddenRoute(
  NOTIFICATIONS,
  lazy(() => import('./index')),
  null
);

export const NotificationRoute = getExactHiddenRoute(
  NOTIFICATION,
  lazy(() => import('./notification')),
  null
);

const NotificationListRoute = (
  <>
    {NotificationsRoute}
    {NotificationRoute}
  </>
)

export default NotificationListRoute;
