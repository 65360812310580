import {createContextHook} from 'util/react';
import {GET_PARENT_CATEGORIES} from 'api/graphql/Category';
import {useQuery} from '@apollo/client';
import {useCallback, useMemo} from 'react';
import {pipe, getPath, map, and, isArray, not, isEmpty, alt, safe, option, chain, isString, tap} from 'crocks';
import {TransportCategory} from '../model/TransportCategory';
import {useTranslation} from 'react-i18next';

const LOCAL_STORAGE = {
  CATEGORIES: 'CategoriesContext.categories'
};

export const {
  CategoriesContext,
  CategoriesContextProvider,
  useCategories,
} = createContextHook('Categories', () => {
  const response = useQuery(GET_PARENT_CATEGORIES);
  const {t} = useTranslation();

  /**
   * @type {TransportCategory[]}
    */
  const categories = useMemo(() => pipe(
    getPath(['data', 'transportation_category']),
    alt(pipe(
      safe(and(isString, not(isEmpty))),
      map(JSON.parse),
      chain(safe(and(isArray, not(isEmpty)))),
    )(localStorage.getItem(LOCAL_STORAGE.CATEGORIES))),
    map(tap(values => localStorage.setItem(LOCAL_STORAGE.CATEGORIES, JSON.stringify(values)))),
    map(map(item => new TransportCategory(item, t))),
    option([]),
  )(response), [response]);

  const getSubCategories = useCallback(categoryId => (
    categories?.find(c => c?.id === categoryId)?.transportation_categories || []
  ), [categories]);

  return {categories, getSubCategories};
});
