import {gql} from '@apollo/client';

export const GET_PARENT_CATEGORIES = gql`
    query GetParentCategories {
        transportation_category(where: {parent_id: {_is_null: true}}) {
            id
            name
            transportation_categories {
                name
                id
            }
        }
    }
`

export const GET_CHILD_CATEGORIES = gql`
    query GetChildCategories($parentName: String = "") {
        transportation_category(where: {parent: {name: {_eq: $parentName}}}) {
            id
            name
            parent_id
        }
    }
`

export const GET_ALL_CATEGORIES = gql`
    query GetAllCategories {
        transportation_category {
            id
            name
            parent_id
        }
    }
`

export const ADD_CATEGORY_TO_CARRIER = gql`
    mutation addCategoryToCarrier($objects: [join_carrier__transportation_category_insert_input!] = {}) {
        insert_join_carrier__transportation_category(objects: $objects) {
            affected_rows
        }
    }

`

export const GET_CARRIER_CATEGORIES = gql`
    query GetCarrierCategories($carrierId: uuid = "") {
        join_carrier__transportation_category(where: {carrier_id: {_eq: $carrierId}}) {
            id
            transportation_category_id
            transportation_category {
                id
                name
                parent_id
            }
        }
    }
`

export const DELETE_CARRIER_CATEGORIES = gql`
    mutation DeleteCarrierCategories($carrierId: uuid = "") {
        delete_join_carrier__transportation_category(where: {carrier_id: {_eq: $carrierId}}) {
            affected_rows
        }
    }

`
