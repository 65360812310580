import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const REVIEWS = '/driver/reviews/:carrierId';
export const CREATE_REVIEW = '/driver/create-review/:id/:to';
export const NEW_ORDERS = '/driver/new-orders';
export const MY_ORDERS = '/driver/my-orders';
export const ARCHIVE = '/driver/archive';
export const ORDER_DETAILS = '/driver/orders/:id';
export const PROFILE = '/driver/profile/working-days';
export const PROFILE_CATEGORIES = '/driver/profile/categories';
export const PROFILE_LOCATIONS = '/driver/profile/locations';
export const PROFILE_DETAILS = '/driver/profile/details';

export const DriverReviewsRoute = getExactHiddenRoute(
  REVIEWS,
  lazy(() => import('./reviews')),
  null
);

export const DriverCreateReviewRoute = getExactHiddenRoute(
  CREATE_REVIEW,
  lazy(() => import('./createReview')),
  null
);

export const DriverNewOrdersRoute = getExactHiddenRoute(
  NEW_ORDERS,
  lazy(() => import('./newOrders')),
  null
);

export const DriverMyOrdersRoute = getExactHiddenRoute(
  MY_ORDERS,
  lazy(() => import('./myOrders')),
  null
);

export const DriverArchiveRoute = getExactHiddenRoute(
  ARCHIVE,
  lazy(() => import('./archive')),
  null
);

export const DriverOrderDetailsRoute = getExactHiddenRoute(
  ORDER_DETAILS,
  lazy(() => import('./orderDetails')),
  null
);

export const DriverProfileRoute = getExactHiddenRoute(
  PROFILE,
  lazy(() => import('./profile/index')),
  null
);

export const DriverProfileCategoriesRoute = getExactHiddenRoute(
  PROFILE_CATEGORIES,
  lazy(() => import('./profile/categories')),
  null
);

export const DriverProfileLocationsRoute = getExactHiddenRoute(
  PROFILE_LOCATIONS,
  lazy(() => import('./profile/locations')),
  null
);

export const DriverProfileDetailsRoute = getExactHiddenRoute(
  PROFILE_DETAILS,
  lazy(() => import('./profile/details')),
  null
);

const DriverRoute = (
  <>
    {DriverReviewsRoute}
    {DriverCreateReviewRoute}
    {DriverNewOrdersRoute}
    {DriverMyOrdersRoute}
    {DriverArchiveRoute}
    {DriverOrderDetailsRoute}
    {DriverProfileRoute}
    {DriverProfileCategoriesRoute}
    {DriverProfileLocationsRoute}
    {DriverProfileDetailsRoute}
  </>
)

export default DriverRoute;
