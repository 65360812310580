import {createContext, useContext, useState} from 'react';

const LoaderContext = createContext();

const LoaderContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [routeLoading, setRouteLoading] = useState(false);

    const triggerLoader = () => {
        setLoading(true);
    };

    const stopLoader = () => {
        setLoading(false);
    };

    return (
        <LoaderContext.Provider value={{
            loading,
            triggerLoader,
            stopLoader
        }}>
            {children}
        </LoaderContext.Provider>
    );
};

const useLoader = () => {
    const context = useContext(LoaderContext);

    if (context === undefined) throw new Error('useLoader must be used within a LoaderContextProvider');

    return context
}

export {LoaderContextProvider, useLoader}
