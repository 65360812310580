import {
  and,
  isNumber,
  not,
  pipe,
  safe,
} from 'crocks';

export const mNumber = pipe(
  parseFloat,
  safe(and(isNumber, not(isNaN)))
);
