import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const PAGE = '/page/:slug';

export const PageRoute = getExactHiddenRoute(
  PAGE,
  lazy(() => import('./index')),
  null
);

const StaticPageRoute = (
  <>
    {PageRoute}
  </>
)

export default StaticPageRoute;
