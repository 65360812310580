import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const MESSAGES = '/messages';
export const MESSAGE = '/messages/:id';

export const MessagesRoute = getExactHiddenRoute(
  MESSAGES,
  lazy(() => import('./index')),
  null
);

export const MessageRoute = getExactHiddenRoute(
  MESSAGE,
  lazy(() => import('./message')),
  null
);

const MessageListRoute = (
  <>
    {MessagesRoute}
    {MessageRoute}
  </>
)

export default MessageListRoute;
