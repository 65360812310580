import {Async, safe, isFunction, constant, isTruthy} from 'crocks';
import {useCallback, useRef} from 'react';

export const useDebounceAsync = (asyncFn = Async.Rejected, defaultDelay = 1000) => {
  const cancelRef = useRef();

  const fork = useCallback((asyncFn, newOnReject, newOnResolve, newDelay) => {
    if (isFunction(cancelRef.current)) cancelRef.current();

    cancelRef.current = (
      Async.resolveAfter(defaultDelay, safe(isTruthy, newDelay).option(defaultDelay))
      .chain(constant(asyncFn))
      .fork(newOnReject, newOnResolve, () => null)
    );
  }, [defaultDelay]);


  return (...params) => Async((reject, resolve) => fork(asyncFn(...params), reject, resolve));
}
