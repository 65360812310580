import React from 'react';
import {defaultProps} from 'crocks';
import {withMergedClassName} from 'util/react';

const Input = props => (
  <input
    {...defaultProps({
      type: 'text',
    }, props)}
  />
);

export default withMergedClassName(
  'px-f bg-transparent appearance-none block sm:text-base shadow-none rounded-[5px] font-medium focus:outline-none focus:ring-0 ',
  Input
);
