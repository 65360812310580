import useMergeReducer from 'hook/useMergeReducer';
import {REFRESH_QUERY} from '../api/graphql/Auth';
import {and, getProp, isEmpty, isFunction, isString, not, safe, tap} from 'crocks';
import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {eraseCookie, getCookie, setCookie} from '../util/cookieUtils';
import {getLink} from 'api/apollo';
import {useApolloClient, useLazyQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import {
  CONFIRMATION_PAGE,
  LOGIN_PAGE,
  LOGOUT_PAGE,
  LOST_PASSWORD_PAGE,
  MAGIC_LINK,
  NOT_ALLOWED,
  REGISTER_PAGE,
  RESET_PASSWORD_PAGE,
  VERIFICATION_PAGE
} from '../pages/auth/routes';

/**
 * @typedef {object} AuthContextValue
 * @property {bool} isClient: bool
 * @property {bool} isCarrier: bool
 * @property {bool} isAuthorized: bool
 * @property {string} authToken: string
 * @property {(newState: object) => void} update
 * @property {(newState: object) => void} setClient
 * @property {(newState: object) => void} setCarrier
 * @property {(variables: object) => (query: string) => Async} api
 * @property {(query: string) => Async} apiQuery
 */

const AuthContext = createContext();
const isValid = and(isString, not(isEmpty));

const DEFAULT_USER_DATA = {
  firstName: null,
  lastName: null,
  address: null,
  email: null,
  avatar: null,
  id: null,
  phone: null,
  carrier: null,
  client: null
};

const UNAUTHORIZED_ROUTES = [
  LOGIN_PAGE, LOGOUT_PAGE, REGISTER_PAGE, VERIFICATION_PAGE,
  LOST_PASSWORD_PAGE, RESET_PASSWORD_PAGE, CONFIRMATION_PAGE, MAGIC_LINK, NOT_ALLOWED
];

const AuthContextProvider = ({children}) => {
  const apolloClient = useApolloClient()
  const userId = getCookie('userId')
  const storedToken = getCookie('token') ?? ''
  const role = getCookie('role') ?? ''
  const clientId = getCookie('clientId')
  const carrierId = getCookie('carrierId')

  const [state, setState] = useMergeReducer({token: null, refreshToken: null});
  const [isAuthorized, setAuthorized] = useState(true);
  const [userData, setUserData] = useState(DEFAULT_USER_DATA);
  const [settings, setSettings] = useState([]);
  const authToken = storedToken ? 'Bearer ' + storedToken : '';

  const switchToCarrier = () => {
    eraseCookie('clientId')
    setCookie('role', 'carrier')
    if (window.Android) {
      window.Android.onUserIdReceived(getCookie('userId'));
    }
  }

  const switchToClient = () => {
    eraseCookie('carrierId')
    setCookie('role', 'client')
    if (window.Android) {
      window.Android.onUserIdReceived(getCookie('userId'));
    }
  }

  const logoutMethod = () => {
    eraseCookie('token');
    eraseCookie('refreshToken');
    eraseCookie('otp_token');
    eraseCookie('otp_updated_at');
    eraseCookie('user');
    eraseCookie('order');
    eraseCookie('userId');
    eraseCookie('phone');
    eraseCookie('role');
    eraseCookie('carrierId');
    eraseCookie('clientId');
    setUserData(DEFAULT_USER_DATA);
    setState({token: null, refreshToken: null, user: null});
    setAuthorized(false);
  }

  const handleFail = useCallback(() => {
    setAuthorized(false);
    eraseCookie('token')
    eraseCookie('refreshToken')
    eraseCookie('clientId')
    eraseCookie('carrierId')
    eraseCookie('role')
    setState({token: null, refreshToken: null});
  }, [setAuthorized, setState]);

  const handleSuccess = useCallback(() => {
    setAuthorized(true);
  }, [role, setAuthorized]);

  const logout = useCallback((callback) => {
    eraseCookie('prevPath')
    logoutMethod();
    isFunction(callback) && callback();
    window.location.href = '/';
  }, [state, setAuthorized]);

  const [refresh, {data}] = useLazyQuery(REFRESH_QUERY);

  const refreshTokenRequest = (always = false) => {
    getProp('refreshToken', state)
      .chain(safe(isValid))
      .map(tap(value => setCookie('refreshToken', value)))
      .alt(safe(isValid, getCookie('refreshToken')))
      .either(handleFail, refreshToken => {
        if (state.token && !always) return handleSuccess();
        refresh({variables: {refreshToken}, fetchPolicy: 'network-only'}).then(res => {
          setCookie('token', res?.data?.refresh?.token, 1)
          setCookie('refreshToken', res?.data?.refresh?.refreshToken)
          res.data ? (setState(res.data.refresh), handleSuccess()) : (handleFail(), logout());
        });
      });
  }

  useEffect(() => {
    refreshTokenRequest()
    if (state?.token) {
      apolloClient.setLink(getLink({
        token: state?.token,
        role,
      }));
    }
  }, [state, handleFail, handleSuccess]);

  return (
    <AuthContext.Provider value={{
      ...state,
      isAuthorized,
      isClient: role === 'client',
      isCarrier: role === 'carrier',
      logoutMethod,
      switchToCarrier,
      switchToClient,
      update: setState,
      logout,
      userData,
      userId,
      authToken,
      role,
      setUserData,
      setSettings,
      settings,
      setAuthorized,
      clientId,
      carrierId,
      refreshTokenRequest
    }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const nav = useNavigate();
  const context = useContext(AuthContext);

  if (context === undefined) throw new Error('useAuth must be used within a AuthProvider');

  const saveCurrentPath = routePath => {
    if (!UNAUTHORIZED_ROUTES.includes(routePath)) {
      setCookie('prevPath', routePath, 1);
    }
  };

  const redirectToPreviousOrDefault = defaultRoute => {
    const prevPath = getCookie('prevPath');
    eraseCookie('prevPath');
    nav(prevPath || defaultRoute);
  };

  return {...context, saveCurrentPath, redirectToPreviousOrDefault};
};

export {AuthContextProvider, useAuth};
