import ArrowDown from 'assets/images/arrow-down.svg'

const Icon = ({...props}) => {
  return (
    <img {...props} src={ArrowDown}/>
  )
}

export default Icon

