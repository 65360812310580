import dayjs from 'dayjs';
import codeList from '../components/form/input/InputAnimated/codeList.json';

export const leftDays = (valid_until, valid_from = new Date()) => {
  const dateJS = dayjs(valid_until)
  return dateJS.diff(valid_from, 'day')
}

export const orderAmount = (monthly_price, daily_price, valid_until, valid_from = new Date()) => {
  const dateJS = dayjs(valid_until)
  let months = dateJS.diff(valid_from, 'month')
  let days = dateJS.diff(dayjs(valid_from).add(months, 'month'), 'day')
  let dailyAmount = days * daily_price > monthly_price ? monthly_price : days * daily_price

  let amount = months * monthly_price + dailyAmount
  return amount
}

export const orderPrice = (monthly_price, daily_price, valid_until, valid_from = new Date()) => {
  return orderAmount(monthly_price, daily_price, valid_until, valid_from) + ' €'
}

export const validPhone = (phone) => {
  let code = codeList.find(code =>
    phone?.startsWith(code.dial_code)
  )

  if (code?.dial_code) {
    let regex = /^\+\d{11}$/;
    return !!(phone && phone.match(regex));
  }

  return false
}

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const yesOrNo = (v) => {
  return v ? 'yes' : 'no'
}

/**
 * @deprecated
 */
export const checkFirstAvailable = (v) => {
  return v[0] ? v[0] : ''
}

/**
 * @deprecated
 */
export const checkSecondAvailable = (v) => {
  return v[1] ? v[1] : v[0] ? v[0] : ''
}

export const calculateExpirationHours = (pickupDate, createdAt, pickupExpireIn, expireIn) => {
  let diff = -1;
  if (pickupDate) {
    const [hours, minutes] = pickupExpireIn?.split(':');
    diff = dayjs(pickupDate).subtract(hours, 'hours')
      .subtract(parseFloat(minutes), 'minutes').diff(dayjs(), 'minutes')
  } else if (expireIn) {
    const [hours, minutes] = expireIn?.split(':');
    diff = dayjs(createdAt).add(hours, 'hours')
      .add(parseFloat(minutes), 'minutes').diff(dayjs(), 'minutes')
  }

  return diff
}
export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = reader.result;
      resolve(base64Data);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const playNotificationSound = () => {
  // Implement your logic to play the sound effect here
  // For example:
  const audio = new Audio(require('../assets/data/notification-client.mp3'));
  audio.play().then().catch();
};

export const calculateTimeDifference = (fromDate, toDate = dayjs()) => {
  const notificationDate = dayjs(fromDate);
  const diffInYears = toDate.diff(notificationDate, 'year');
  const diffInDays = toDate.diff(notificationDate, 'day');
  const diffInHours = toDate.diff(notificationDate, 'hour');
  const diffInMinutes = toDate.diff(notificationDate, 'minute');

  let result = '';

  if (diffInYears > 0) {
    result += `${diffInYears} metai `;
  }
  if (diffInYears <= 0 && diffInDays > 0) {
    result += `${diffInDays} diena `;
  }
  if ((diffInYears <= 0 && diffInDays <= 0) && diffInHours > 0) {
    result += `${diffInHours} valanda `;
  }
  if ((diffInYears <= 0 && diffInDays <= 0 && diffInHours <= 0) && diffInMinutes > 0) {
    result += `${diffInMinutes} min. `;
  }

  return result.trim();
};

//transform translation.json to graphql input
function transformInput(input, parentKey = '') {
  const output = [];

  for (let key in input) {
    const combinedKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
      output.push(...transformInput(input[key], combinedKey));
    } else {
      output.push({
        key: combinedKey,
        language: 'lt',
        value: input[key]
      });
    }
  }

  return output;
}

export const isValidLithuanianVAT = (vatNumber) => {
  // Check for the LT prefix and remove it for further validation
  if (!vatNumber)
    return false;

  if (!vatNumber.startsWith('LT')) {
    return false;
  }

  // Extract the number part after 'LT'
  const cleanVAT = vatNumber.slice(2).replace(/\D/g, '');

  // Check if the length is either 9 or 12 digits
  if (cleanVAT.length !== 9 && cleanVAT.length !== 12) {
    return false;
  }

  // Implement additional checks as per Lithuanian VAT rules if necessary
  // For now, this function only checks the prefix and length

  return true;
}

export const validatePassword = (v) => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
  return passwordRegex.test(v)
};

export const validateNumericInputValue = (value) => {
  // Allow digits, commas, and dots
  value = value.replace(/[^\d.,]/g, '');

  // Replace all commas with dots for consistency
  value = value.replace(/,/g, '.');

  // Split based on dots and handle multiple separators
  const parts = value.split('.');
  if (parts.length > 2) {
    // Keep the first part and join the rest without additional dots
    value = parts[0] + '.' + parts.slice(1).join('');
  }

  return value;
}

export const validateNumericInput = ({set}) => ({target: {value}}) => {
  set(validateNumericInputValue(value));
}
