import env from 'env';
import {getCookie} from 'util/cookieUtils';
import {getMainDefinition} from '@apollo/client/utilities';
import {setContext} from '@apollo/client/link/context';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {split, ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import {createClient} from 'graphql-ws';

export const getLink = ({
  token = getCookie('token', ''),
  role = getCookie('role', ''),
} = {}) => split(
    ({query}) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    new GraphQLWsLink(createClient({
      url: env.SUBSCRIPTION_BASE_URL,
      lazy: true,
      connectionParams: {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'x-hasura-role': role,
        },
      }
    })),
    setContext((_, {headers}) => {
      let header = {
        ...headers,
        authorization: token ? `Bearer ${token}` : undefined,
        'x-hasura-role': role,
      }

      Object.keys(header).forEach(key => header[key] === undefined && delete header[key])

      return {
        headers: {
          ...header
        }
      }
    }).concat(createHttpLink({uri: env.API_ENDPOINT})),
  );

export const getClient = ({token, role} = {}) => new ApolloClient({
  cache: new InMemoryCache(),
  link: getLink({token, role}),
});
