import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import ltTranslations from 'assets/translation.json'; // Import local translation file
import env from 'env';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'lt', // Default language
    fallbackLng: 'lt', // Fallback language if translation for the current language is not available
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
    resources: { // Add this block
      lt: {
        translation: ltTranslations
      },
    },
    backend: {
      // configure your backend endpoint for fetching translations
      loadPath: `${env.TRANSLATION_URL}/api/translations/{{lng}}`, // The URL pattern to fetch translations
      allowMultiLoading: false, // Set to true if you want to load multiple language files at once
    },
    // Add other configuration options here, if needed.
  });

export default i18n;
