import ArrowUp from 'assets/images/arrow-up.svg'

const Icon = ({...props}) => {
  return (
    <img className={'w-[14px] h-[14px]'} {...props} src={ArrowUp}/>
  )
}

export default Icon

