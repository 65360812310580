import {gql} from '@apollo/client';

export const LOGIN_QUERY = gql`
    query Login($applicationId: String = "", $password: String = "", $username: String = "") {
        login(applicationId: $applicationId, password: $password, username: $username) {
            refreshToken
            token
            user {
                id
                email
                firstName
                lastName
                username
                mobilePhone
                registrations {
                    roles
                    applicationId
                    id
                }
            }
        }
    }
`;

export const REFRESH_QUERY = gql`
    query Refresh ($refreshToken: String!) {
        refresh (refreshToken: $refreshToken) {
            token
            refreshToken
        }
    }
`;

export const REGISTER_USER = gql`
    mutation RegistrationWithOtp($email: String = "", $firstName: String = "", $lastName: String = "", $mobilePhone: String = "", $password: String = "", $role: String = "", $subscribe: Boolean = false) {
        registrationWithOtp(email: $email, firstName: $firstName, lastName: $lastName, mobilePhone: $mobilePhone, password: $password, role: $role, subscribe: $subscribe) {
            response
        }
    }
`;


export const CREATE_CARRIER = gql`
    mutation CreateCarrier($user_id: uuid!, $email: String!, $first_name: String = "", $last_name: String = "", $phone: String = "") {
        insert_user_profile(objects: {user_profile_carrier: {data: {user_id: $user_id}}, email: $email, first_name: $first_name, last_name: $last_name, phone: $phone}) {
            returning {
                phone
                address
                avatar
                email
                email_verified_at
                user_profile_carrier {
                    id
                    user_id
                    status
                }
            }
        }
    }
`;

export const CREATE_CLIENT = gql`
    mutation CreateClient($user_id: uuid!, $email: String!, $first_name: String = "", $last_name: String = "", $phone: String = "") {
        insert_user_profile(objects: {user_profile_client: {data: {user_id: $user_id}}, first_name: $first_name, email: $email, last_name: $last_name, phone: $phone}) {
            returning {
                phone
                address
                avatar
                email
                email_verified_at
                user_profile_carrier {
                    id
                    user_id
                    status
                }
            }
        }
    }
`;

export const UPDATE_CARRIER = gql`
    mutation MyMutation($status: user_status_enum!, $user_id: uuid = "") {
        update_carrier(where: {user_id: {_eq: $user_id}}, _set: {status: $status}) {
            returning {
                company_id
                description
                id
                kickback_rate
                status
                user_id
            }
        }
    }
`;

export const UPDATE_CLIENT = gql`
    mutation UpdateClient($user_id: uuid = "", $status: user_status_enum!) {
        update_client(where: {user_id: {_eq: $user_id}}, _set: {status: $status}) {
            returning {
                company_id
                discount
                id
                status
                user_id
            }
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation updateUserPassword($phone: String!, $password: String!, $otp_token: String!) {
        updateUserPassword(phone: $phone, password: $password, otp_token: $otp_token) {
            otp,
            phone,
            updated_at,
            status,
            otp_token
        }
    }

`;
export const ADD_CLIENT_TO_CARRIER = gql`
    mutation AddClientToCarrier($user_id: uuid!) {
        insert_client_one(object: {user_id: $user_id}) {
            user_id
            id
            status
            company_id
        }
    }
`;
export const ADD_ROLES_TO_USER = gql`
    mutation AddRolesToUser($appId: uuid!, $roles: [String]!, $userId: uuid!) {
        updateFusionRoles(userId: $userId, appId: $appId, roles: $roles) {
            registration {
                id
                roles
            }
        }
    }
`;
export const ADD_CARRIER_TO_CLIENT = gql`
    mutation AddCarrierToClient($user_id: uuid!) {
        insert_carrier_one(object: {user_id: $user_id, status: UNAPPROVED}, on_conflict: {constraint: carrier_user_id_key}) {
            description
            user_id
            id
            status
            company_id
        }
    }
`;

export const GET_USER_BY_PHONE = gql`
    query getUserByPhone($username: String = "") {
        getUserByPhone(username: $username) {
            user {
                active
                birthDate
                breachedPasswordLastCheckedInstant
                connectorId
                lastName
                middleName
                mobilePhone
                username
                usernameStatus
                verified
                firstName
                fullName
                id
                expiry
                email
                imageUrl
                lastLoginInstant
            }
        }
    }
`;

export const CONFIRM_TOKEN = gql`
    mutation ConfirmationToken($token: String!) {
        confirmEmail(token: $token) {
            response
        }
    }
`;

export const GOOGLE_LOGIN = gql`
    mutation GoogleLogin($token: String!, $role: String!, $platform: String = "") {
        googleLogin(token: $token, role: $role, platform: $platform) {
            response
        }
    }
`;

export const FACEBOOK_LOGIN = gql`
    mutation FacebookLogin($token: String!, $role: String!) {
        facebookLogin(token: $token, role: $role) {
            response
        }
    }
`;

export const PASSWORDLESS_LOGIN_END = gql`
    query PasswordlessLoginEnd($code: String = "") {
        passwordlessLoginEnd(code: $code) {
            refreshToken
            token
            user {
                id
                username
            }
        }
    }
`
export const LOGIN_WITH_OTP = gql`
    mutation LoginWithOtp($password: String = "", $role: String = "", $username: String = "") {
        loginWithOtp(password: $password, role: $role, username: $username) {
            response
        }
    }
`
