import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const REVIEWS = '/client/reviews/:clientId';
export const CREATE_REVIEW = '/client/create-review/:id/:to';
export const PROFILE = '/client/profile';
export const ACTIVE_ORDERS = '/client/active-orders';
export const ARCHIVE = '/client/archive';
export const NEW_ORDERS = '/client/new-orders';
export const BIDS = '/client/orders/:id/bids';
export const BID_DETAILS = '/client/orders/:id/bids/:bidId';

export const ClientProfileRoute = getExactHiddenRoute(
  PROFILE,
  lazy(() => import('./profile')),
  null
);

export const ClientBidsRoute = getExactHiddenRoute(
  BIDS,
  lazy(() => import('./bids')),
  null
);

export const ClientBidDetailsRoute = getExactHiddenRoute(
  BID_DETAILS,
  lazy(() => import('./bidDetails')),
  null
);

export const ClientActiveOrdersRoute = getExactHiddenRoute(
  ACTIVE_ORDERS,
  lazy(() => import('./activeAOrders')),
  null
);

export const ClientArchiveRoute = getExactHiddenRoute(
  ARCHIVE,
  lazy(() => import('./archive')),
  null
);

export const ClientNewOrdersRoute = getExactHiddenRoute(
  NEW_ORDERS,
  lazy(() => import('./newOrders')),
  null
);

export const ClientReviewsRoute = getExactHiddenRoute(
  REVIEWS,
  lazy(() => import('./reviews')),
  null
);

export const ClientCreateReviewRoute = getExactHiddenRoute(
  CREATE_REVIEW,
  lazy(() => import('./createReview')),
  null
);

const ClientRoute = (
  <>
    {ClientNewOrdersRoute}
    {ClientBidsRoute}
    {ClientProfileRoute}
    {ClientActiveOrdersRoute}
    {ClientArchiveRoute}
    {ClientReviewsRoute}
    {ClientCreateReviewRoute}
    {ClientBidDetailsRoute}
  </>
)

export default ClientRoute;
