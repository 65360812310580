import {gql} from '@apollo/client';

export const CREATE_SHIPMENT = gql`
    mutation CreateShipment($order_id: uuid = "", $service_provider: String = "", $packages: Int = 1, $confirmed: Int = 0, $id: String = "", $price: numeric = "") {
        insert_shipment_one(object: {order_id: $order_id, service_provider: $service_provider, packages: $packages, confirmed: $confirmed, id: $id, price: $price}) {
            id
        }
        update_order_by_pk(pk_columns: {id: $order_id}, _set: {shipment_id: $id}) {
            id
        }
    }
`

/**
 * @type {typeof import('gql/graphql').GetOrderByIdShipmentDocument}
 */
export const GET_SHIPMENT_BY_ORDER_ID = gql`
    query GetOrderByIdShipment($id: uuid!) {
        order_by_pk(id: $id) {
            confirmed_at
            collected_at
            completed_at
            client_id
            created_at
            currency
            id
            from_delivery_address_id
            insurance_rate
            number
            order_shipment {
                service_provider
                id
                price
                manifest_id
                tracking_url
            }
            order_cargos {
                category_id
                description
                height
                id
                insured_price
                is_insured
                is_packed
                length
                order_id
                price
                quantity
                weight
                width
            }
            price
            insurance_price
            status
            to_delivery_address_id
            updated_at
            wanted_delivery_date_from
            wanted_delivery_date_to
            wanted_pickup_date_from
            wanted_pickup_date_to
            description
            pictures {
                src
                id
            }
            order_receiver {
                comment
                company_name
                company_phone
                id
                name
                phone
            }
            order_sender {
                comment
                company_name
                company_phone
                id
                name
                phone
            }
            payments {
                payment_invoice {
                    number
                    id
                    order_id
                }
            }
            client {
                feedbacks_aggregate {
                    aggregate {
                        count
                        avg {
                            rating
                        }
                    }
                }
                client_user_profile {
                    user_profile_client {
                        user_id
                    }
                    user_id
                    address
                    last_name
                    first_name
                    email
                }
            }
            from {
                address
                building_no
                city
                country
                created_at
                door_no
                floor
                full_address
                id
                is_elevator_available
                is_extraction_assisted
                is_extraction_required
                zip_code
            }
            to {
                address
                building_no
                city
                country
                created_at
                door_no
                floor
                full_address
                id
                is_elevator_available
                is_extraction_assisted
                is_extraction_required
                zip_code
            }
        }
    }
`
