import ArrowDownIcon from '../ui-icons/ArrowDownIcon';
import ArrowUpIcon from '../ui-icons/ArrowUpIcon';
import CalendarIcon from '../ui-icons/CalendarIcon';
import ClockIcon from '../ui-icons/ClockIcon';
import {putIntoArray, unique} from 'util/array';
import {useClientOrder} from 'feature/order/context/ClientOrderContext';
import {useTranslation} from 'react-i18next';
import {yesOrNo} from 'util/helpers';
import {
  Maybe,
  chain,
  filter,
  getProp,
  isEmpty,
  isString,
  isTruthy,
  map,
  not,
  option,
  pipe,
  safe,
} from 'crocks';

const DtRange = ({icon, range}) => (
  safe(not(isEmpty), putIntoArray(range))
    .map(pipe(
      unique,
      list => list.join(' - '),
      dates => (
        <div className={'flex items-center gap-2'}>
          {icon} {dates}
        </div>
      )
    ))
    .option(null)
);

export const getFormAddressLine = form => {
  const sanitize = pipe(
    safe(isString),
    map(a => String(a).trim()),
    chain(safe(isTruthy)),
  )

  return pipe(
    form => [
      getProp('street', form).chain(sanitize).option(null),

      Maybe.of(house => flat => [house, flat])
        .ap(getProp('house', form).chain(sanitize).alt(Maybe.of(null)))
        .ap(getProp('flat', form).chain(sanitize).alt(Maybe.of(null)))
        .map(values => values.filter(isTruthy).join('-'))
        .option(null),

      getProp('city', form).chain(sanitize).option(null),
      getProp('zipCode', form).chain(sanitize).option(null),
      getProp('country', form).chain(sanitize).option(null),
    ],
    filter(isTruthy),
    list => list.join(', '),
    safe(not(isEmpty)),
    option(null)
  )(form)
};

const UserInfoOverview = ({pickup = false, delivery = false}) => {
  const {t} = useTranslation()
  const ctx = useClientOrder();

  const v = (onPickup, onDelivery) => {
    if (pickup) return onPickup;
    if (delivery) return onDelivery;
    return null;
  };

  const f = (fn, onPickup, onDelivery) => {
    if (pickup) return fn(...onPickup);
    if (delivery) return fn(...onDelivery);
    return null;
  };

  const renderNonEmpty = (mapper, value) => pipe(
    safe(not(isEmpty)),
    map(mapper),
    option(null),
  )(value);

  return (
    <>
      <table className={'text-sm md:text-base font-medium w-full'}>
        <tbody>
        <tr className={'[&>td]:pb-t'}>
          <td className={'flex items-center gap-2 font-bold text-primary'}>
            {v(<ArrowUpIcon/>, <ArrowDownIcon/>)}
            {v(t('pickup'), t('delivery'))}
          </td>
          <td>
            <div className={'flex gap-x-4 gap-y-t flex-wrap'}>
              {f(
                (dateRange, timeRange) => (
                  <>
                    <DtRange icon={<CalendarIcon/>} range={dateRange}/>
                    <DtRange icon={<ClockIcon/>} range={timeRange}/>
                  </>
                ),
                [ctx.pickupDatetime.form.date, ctx.pickupDatetime.form.time],
                [ctx.deliveryDatetime.form.date, ctx.deliveryDatetime.form.time],
              )}
            </div>
          </td>
        </tr>

        {renderNonEmpty(
          value => (
            <tr className={'[&>td]:pb-t'}>
              <td className={'align-top'}>{t('address')}:</td>
              <td>{value}</td>
            </tr>
          ), getFormAddressLine(v(ctx.origin.form, ctx.destination.form)))}

        {renderNonEmpty(value => (
          <tr className={'[&>td]:pb-t'}>
            <td className={'align-top'}>{t('floor')}:</td>
            <td>{value}</td>
          </tr>
        ), v(ctx.origin.form.floor, ctx.destination.form.floor))}

        {f(value => (
          <tr className={'[&>td]:pb-t'}>
            <td>{t('has_elevator')}</td>
            <td>{t(yesOrNo(value))}</td>
          </tr>
        ), [ctx.pickupDetails.form.hasLift], [ctx.deliveryDetails.form.hasLift])}

        {f(value => (
          <tr className={'[&>td]:pb-t'}>
            <td className={'w-[190px]'}>{t('need_takeout')}</td>
            <td>{t(yesOrNo(value))}</td>
          </tr>
        ), [ctx.pickupDetails.form.needTakeout], [ctx.deliveryDetails.form.needTakeout])}

        {f(value => (
          <tr className={'[&>td]:pb-5 [&>td]:border-b'}>
            <td>{t('carry_yourself')}</td>
            <td>{t(yesOrNo(value))}</td>
          </tr>
        ), [ctx.pickupDetails.form.carryYourself], [ctx.deliveryDetails.form.carryYourself])}

        {f(
          (name, phone) => (
            <tr className={'[&>td]:pb-t font-bold  [&>td]:pt-5'}>
              <td className={'align-top'}>{v(t('cargo_be_delivered'), t('cargo_be_accepted'))}:</td>
              <td>
                <div className={'flex gap-4 items-center'}>
                  {name ? <div>{name}</div> : null}
                  {phone ? <div className={'text-subtext'}>{phone}</div> : null}
                </div>
              </td>
            </tr>
          ),
          [
            ctx.pickupDetails.form.name,
            ctx.pickupDetails.form.phone,
          ],
          [
            ctx.deliveryDetails.form.name,
            ctx.deliveryDetails.form.phone,
          ]
        )}
        </tbody>
      </table>
    </>
  )
}
export default UserInfoOverview
