import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const REVIEWS_PAGE = '/reviews';

const AllReviewsRoute = getExactHiddenRoute(
    REVIEWS_PAGE,
  lazy(() => import('./index')),
  null
);

export default AllReviewsRoute;
