import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const CANCELLED = '/order-status/cancelled/:id'
export const ORDER_STATUS = '/order-status/:id'
export const SHIPMENT_STATUS = '/shipment-status/:id'
export const SUCCESS = '/order-status/sent-to-driver/:id'
export const CANCELLED_BY_DRIVER = '/order-status/cancelled-by-driver/:id'

export const OrderCancelledRoute = getExactHiddenRoute(
  CANCELLED,
  lazy(() => import('./cancelled')),
  null
);

export const OrderSentToDriverRoute = getExactHiddenRoute(
  SUCCESS,
  lazy(() => import('./sentToDriver')),
  null
);

export const OrderStatusRoute = getExactHiddenRoute(
  ORDER_STATUS,
  lazy(() => import('./index')),
  null
);

export const ShipmentStatusRoute = getExactHiddenRoute(
  SHIPMENT_STATUS,
  lazy(() => import('./shipment-status')),
  null
);

export const OrderCancelledByDriverRoute = getExactHiddenRoute(
  CANCELLED_BY_DRIVER,
  lazy(() => import('./cancelledByDriver')),
  null
);


const OrderStatusesRoute = (
  <>
    {OrderCancelledRoute}
    {OrderSentToDriverRoute}
    {OrderStatusRoute}
    {OrderCancelledByDriverRoute}
    {ShipmentStatusRoute}
  </>
)

export default OrderStatusesRoute;
