import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const SELECT_CATEGORY = '/create-order/select-category';
export const SELECT_ROUTE = '/create-order/select-route';
export const CARGO_INFO = '/create-order/cargo-info';
export const LAST_STEP_ROUTE = '/create-order/overview';
export const DAMAGE_REPORT_ROUTE = '/create-order/damage-report/:id';
export const PAYMENT_ROUTE = '/create-order/payment/:id';

export const SelectCategoryRoute = getExactHiddenRoute(
  SELECT_CATEGORY,
  lazy(() => import('./selectCategory')),
  null
);

export const PaymentRoute = getExactHiddenRoute(
  PAYMENT_ROUTE,
  lazy(() => import('./payment')),
  null
);

export const SelectRouteRoute = getExactHiddenRoute(
  SELECT_ROUTE,
  lazy(() => import('./selectRoute')),
  null
);

export const OrderOverviewRoute = getExactHiddenRoute(
  LAST_STEP_ROUTE,
  lazy(() => import('./overview')),
  null
);

export const DamageReportRoute = getExactHiddenRoute(
  DAMAGE_REPORT_ROUTE,
  lazy(() => import('./damageReport')),
  null
);


export const CargoInfoRoute = getExactHiddenRoute(
  CARGO_INFO,
  lazy(() => import('./cargoInformation')),
  null
);


const CreateOrderRoute = (
  <>
    {SelectCategoryRoute}
    {SelectRouteRoute}
    {OrderOverviewRoute}
    {DamageReportRoute}
    {CargoInfoRoute}
    {PaymentRoute}
  </>
)

export default CreateOrderRoute;
