import Cookies from 'js-cookie';

export const setCookie = (key, value, days = 7) => {
    return Cookies.set(key, value, {expires: days})
}

export const getCookie = (key, defaultValue = null) => {
    return Cookies.get(key) ?? defaultValue
}

export const eraseCookie = (key) => {
    if (getCookie(key, false)) {
        Cookies.remove(key)
    }
}
