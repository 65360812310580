import './assets/tailwind.css';
import './i18n'
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import env from './env';
import reportWebVitals from './reportWebVitals';
import {APIProvider} from '@vis.gl/react-google-maps';
import {AuthContextProvider} from 'context/auth';
import {CategoriesContextProvider} from 'feature/category/context/CategoriesContext';
import {ClientOrderContextProvider} from 'feature/order/context/ClientOrderContext';
import {CouriersContextProvider} from 'feature/courier/context/useCouriers';
import {FirebaseContextProvider} from 'context/firebaseContext'
import {LoaderContextProvider} from 'context/loader'
import {NotificationContextProvider} from 'components/ui-notifications/context';
import {SettingsContextProvider} from 'feature/settings/context/SettingsContext';
import {createRoot} from 'react-dom/client';
import {ApolloProvider} from '@apollo/client';
import {getClient} from 'api/apollo';

const Root = createRoot(document.getElementById('root'));

Root.render(
  <NotificationContextProvider>
    <ApolloProvider client={getClient()}>
      <SettingsContextProvider>
        <AuthContextProvider>
          <FirebaseContextProvider>
            <APIProvider apiKey={env.GOOGLE_MAPS_API_KEY}>
              <LoaderContextProvider>
                <CategoriesContextProvider>
                  <ClientOrderContextProvider>
                    <CouriersContextProvider>
                      <App/>
                    </CouriersContextProvider>
                  </ClientOrderContextProvider>
                </CategoriesContextProvider>
              </LoaderContextProvider>
            </APIProvider>
          </FirebaseContextProvider>
        </AuthContextProvider>
      </SettingsContextProvider>
    </ApolloProvider>
  </NotificationContextProvider>
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
