export class TransportCategory {
  constructor({
    id,
    name,
    transportation_categories,
  }, translate) {
    this.id = id;
    this.name = translate ? translate(name) : name;
    this.rawName = name;
    this.transportation_categories = (transportation_categories || [])?.map(t => new TransportCategory(t, translate));
  };
};
